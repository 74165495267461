import * as React from 'react';

import styled from '@emotion/styled';

import { Box, Button, COLORS, Text, mq } from '@clutter/clean';

import { Carousel, useCarousel } from '@shared/carousel';
import { ResponsiveImage } from '@shared/responsive_image';

import life1 from '@images_responsive/www/careers/life1.jpg';
import life2 from '@images_responsive/www/careers/life2.jpg';
import life4 from '@images_responsive/www/careers/life4.jpg';
import life5 from '@images_responsive/www/careers/life5.jpg';
import life6 from '@images_responsive/www/careers/life6.jpg';
import life7 from '@images_responsive/www/careers/life7.jpg';

import { Arrow } from '@images/svg/arrow';

export const lifeImages = [life7, life4, life5, life6, life1, life2];

const StyledCarousel = styled(Carousel)`
  max-width: 920px;
  overflow: visible;
  margin: 0 auto;
  padding: 0 20px;

  & * {
    user-select: none;
    -webkit-user-drag: none;
  }
`;

const Card = styled.div`
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  border: none;
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 768px) {
    min-height: 300px;
  }

  @media (min-width: 1440px) {
    min-height: 250px;
  }

  ${mq({
    margin: ['0 8px', '0 16px'],
    padding: ['4px', '20px'],
  })}
`;

const NavButton = styled(Button)`
  min-width: initial;
  width: 50px;
  padding: 4px 0 0;
  transform: translateY(-50%);
`;

const LifeCarousel: React.FunctionComponent<{ disableWt?: boolean }> = () => {
  const props = useCarousel();

  return (
    <Box
      padding={['48px 0', '100px 0']}
      background={COLORS.tealBackground}
      overflow="hidden"
    >
      <Box textAlign="center" margin={['0 0 24px', '0 0 48px']}>
        <Text.Title size="large">Life at Clutter</Text.Title>
      </Box>
      <Box position="relative">
        <StyledCarousel {...props} slidesToShow={1}>
          {lifeImages.map((image, i) => (
            <Card key={i}>
              <ResponsiveImage image={image} alt="" />
            </Card>
          ))}
        </StyledCarousel>
        <Box position="absolute" top="50%" left={['24px', '32px']}>
          <NavButton onClick={props.prev} aria-label="Previous Slide">
            <Arrow fillColor={COLORS.cloud} />
          </NavButton>
        </Box>
        <Box position="absolute" top="50%" right={['24px', '32px']}>
          <NavButton onClick={props.next} aria-label="Next Slide">
            <Arrow direction="right" fillColor={COLORS.cloud} />
          </NavButton>
        </Box>
      </Box>
    </Box>
  );
};

export { LifeCarousel };
