import * as React from 'react';
import { Route } from 'react-router-dom';

import { CallbackRequest } from '@root/components/www/callback_request';
import { Careers } from '@root/components/www/careers';
import { Company } from '@root/components/www/company';
import { Help } from '@root/components/www/help';
import { Home } from '@root/components/www/home';
import { StorageAndMovingServicePage } from '@root/components/www/home/services/storage_and_moving_service_page';
import { Legal } from '@root/components/www/legal';

import { ErrorBoundary } from '@shared/error_boundary';
import { SwitchWithSharedRoutes } from '@shared/switch_with_shared_routes';

import { Guide } from '../../components/www/guide';
import { MovingServicePage } from '../../components/www/home/services/moving_service_page';
import { WarehouseStorageServicePage } from '../../components/www/home/services/warehouse_storage_service_page';
import { WWW_ROUTES } from '../routes';

export const Routes: React.FC = () => {
  return (
    <ErrorBoundary>
      <SwitchWithSharedRoutes>
        <Route path="/legal" component={Legal} />
        <Route path="/help" component={Help} />
        <Route path="/guide" component={Guide} />
        <Route path="/company" component={Company} />
        <Route path="/careers" component={Careers} />
        <Route path="/cr/:slug" component={CallbackRequest} />
        <Route
          path={WWW_ROUTES.SERVICE_WAREHOUSE_STORAGE}
          component={WarehouseStorageServicePage}
        />
        <Route
          path={WWW_ROUTES.SERVICE_STORAGE_AND_MOVING}
          component={StorageAndMovingServicePage}
        />
        <Route
          path={WWW_ROUTES.SERVICE_MOVING}
          component={MovingServicePage}
          exact
        />
        <Route path="/" exact={true} component={Home} />
      </SwitchWithSharedRoutes>
    </ErrorBoundary>
  );
};
