import React from 'react';

import { Box, COLORS, Text } from '@clutter/clean';

import { Checkmark } from '@root/components/icons/checkmark';

const DATA = [
  {
    title: 'Safety for Everyone & Everything',
    description:
      'We provide the training and resources to ensure the safety of our people, our customers, and their belongings.',
  },
  {
    title: 'Pride in Professionalism',
    description:
      'In every location and every interaction, we are consummate professionals whose integrity earns the trust of our customers and colleagues. ',
  },
  {
    title: 'Confident Optimism',
    description:
      'We believe in our ability to build a successful future that has never been achieved. Our positive attitude and agility provide stability in times of change.',
  },
  {
    title: 'High Standards, Real Accountability',
    description:
      'Outstanding performance isn’t something we just talk about. We hold each other to high standards, admit when we’re wrong and champion the best ideas.',
  },
];

export const CoreValues: React.FC = () => {
  return (
    <Box padding={['48px 24px', null, '100px 24px']} background={COLORS.cloud}>
      <Box.Flex
        maxWidth="1020px"
        margin="0 auto"
        flexDirection={['column', null, 'row']}
      >
        <Box.FlexItem flexShrink={0} textAlign={['center', null, 'left']}>
          <Text.Title size="large">Core Values</Text.Title>
        </Box.FlexItem>
        <Box.FlexItem
          flexGrow={1}
          margin={[
            '32px auto -48px',
            null,
            '0 0 -60px 60px',
            '0 0 -60px 100px',
          ]}
        >
          {DATA.map(({ title, description }, i) => (
            <Box key={i} margin={['0 auto 48px', null, '0 16px 60px']}>
              <Box.Flex>
                <Box.Flex
                  flexShrink={0}
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="50%"
                  height="20px"
                  width="20px"
                  margin="4px 16px 0 0"
                  background={COLORS.tealPrimary}
                >
                  <Checkmark width={10} fillColor={COLORS.cloud} />
                </Box.Flex>
                <Box>
                  <Text.Title size="extraSmall">{title}</Text.Title>
                  <Text.Body>{description}</Text.Body>
                </Box>
              </Box.Flex>
            </Box>
          ))}
        </Box.FlexItem>
      </Box.Flex>
    </Box>
  );
};
